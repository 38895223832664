
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './App.css';

interface NotificationProps {
  message: string;
  onClose: () => void;
  backgroundColor?: string;
}

const Notification: React.FC<NotificationProps> = ({ message, onClose, backgroundColor }) => {
  const [isVisible, setIsVisible] = useState(true);

  const [indexLanguage, setIndexLanguage] = useState('');

  useEffect(() => {
    // Get the language from localStorage when the component mounts
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
    setIndexLanguage(storedLanguage);
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  const notificationStyle = {
    backgroundColor: backgroundColor || '#ffffff',
  };

  return isVisible ? (
    <div className="notification-container" style={notificationStyle}>
      <div className="notification-message">{message}</div>
    </div>
  ) : null;
};

export default Notification;

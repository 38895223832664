import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./App.css";

interface PaymentProps {
  amountGE: number;
  description: string;
  owner: string;
  currency: string;
  buyer: string;
  orderPass: any[];
}


const stripePromise = loadStripe("pk_test_51OUV28E9rT114Xkd3hicFE8W5g6GlsgQJz1pvmpDAVF9KBWB1377rgydEUnzdFS9w1RGRidsxHaSAz5cBpmNbkh200eDgslAYD");

const Payment: React.FC<PaymentProps> = ({ amountGE, description, owner, currency, buyer, orderPass }) => {
const [clientSecret, setClientSecret] = useState("");

const [indexLanguage, setIndexLanguage] = useState('');

useEffect(() => {
  // Get the language from localStorage when the component mounts
  const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
  setIndexLanguage(storedLanguage);
}, []); // Empty dependency array ensures this runs once on mount

 /*useEffect(() => {



    const serverURL = "https://peaxel.me/wp-json/custom/v1";

    fetch(`${serverURL}/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        items: [
          { id: "DirectSale", }],
          amount:amountGE,
          currency:currency,
      }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []); */

 




  //console.log('theAmount',amountGE);

  orderPass.forEach((offer: any) => {
    offer.client_secret_stripe = clientSecret;
  });

  //console.log('Pass',orderPass);

  const appearance = {
    theme: "night",
  };

  const options = {
    clientSecret,
    appearance: {
      labels: "floating",
      theme: "flat",
      variables: {
        colorBackground: "#101010",
        colorText: "#818181",
        colorDanger: "#d51bdf",
      },
      rules: {
        ".Input": {
          border: "0.5px solid #515151",
        },
      },
    },
  } as const;

  return (
    <div className="Payment">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm orderPass2={orderPass} />
        </Elements>
      )}
    </div>
  );
};

export default Payment;

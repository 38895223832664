import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import Notification from './notifications';

interface CheckoutFormProps {
  orderPass2: any[]; // Add orderData prop
}

const saveSalesData = async (data: any) => {
  // Make the API request for each item in the data array
  for (const item of data) {
    try {
      const serverURL = "https://peaxel.me/wp-json/custom/v1";

      await fetch(`${serverURL}/sales-registry/`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(item),
      });

      //console.log('Data saved successfully');
    } catch (error) {
      console.error('Fetch error:', error);
    }
  }
};

async function cleanOrders(orderID: any) {
  try {
    // Fetch data based on orderID
    const selectionResponse = await fetch(`https://peaxel.me/wp-json/custom/v1/sales-request-registry?columnA=order_number&valueA=${orderID}&columnB=ref`);
    const selection = await selectionResponse.json();
    //console.log('selection',selection);

    // Loop through each refx in the selection
    for (const refx of selection) {
      // Convert refx to a string
      const ref = `${refx}`;
      //console.log('ref',ref);

      // Fetch data based on ref
      const statusRefResponse = await fetch(`https://peaxel.me/wp-json/custom/v1/sales-request-registry?columnA=ref&valueA=${ref}&columnB=status`);
      const statusRef = String(await statusRefResponse.json());

      //console.log('status Ref',statusRef);

      // Check if status is "Pending" or "toTransfer"
      if (statusRef === "Pending" || statusRef === "toTransfer") {
        // Create clean order data with ref as a template literal
        const cleanOrder = [
          {
            ref: `${ref}`,
            status: 'Cancelled',
          }
        ];
        saveSalesData(cleanOrder);
        // Save clean order data
        // Note: Adjust the saving logic based on your requirements
        //console.log('Saving data:', cleanOrder);
      }
    }

    // End of the loop
  } catch (error) {
    console.error('Error processing orders:', error);
  }
}

export default function CheckoutForm({ orderPass2 }: CheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [notification, setNotification] = useState<{ message: string; backgroundColor?: string } | null>(null);


  //console.log('pass2', orderPass2);

  const triggerNotificationWithMessage = (message: string, backgroundColor?: string) => {
    setNotification({ message, backgroundColor });
  };


const closeNotification = () => {
  setNotification(null);
};

const [indexLanguage, setIndexLanguage] = useState('');

useEffect(() => {
  // Get the language from localStorage when the component mounts
  const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
  setIndexLanguage(storedLanguage);
}, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    const fetchData = async () => {
      if (!stripe) {
        return;
      }

      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      try {
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

        if (paymentIntent) {
          switch (paymentIntent.status) {
            case "succeeded":
              setMessage("Payment succeeded!");
              break;
            case "processing":
              setMessage("Your payment is processing.");
              break;
            case "requires_payment_method":
              setMessage("Your payment was not successful, please try again.");
              break;
            default:
              setMessage("Something went wrong.");
              break;
          }
        } else {
          // Handle the case where paymentIntent is undefined
          setMessage("Unable to retrieve payment status. Please check your connection.");
        }
      } catch (error) {
        console.error('Error retrieving paymentIntent:', error);
      }
    };

    fetchData();
  }, [stripe]);

  const handleSubmit = async (e:any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

  

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "",
      },
      redirect: "if_required",
    });

    //console.log('errorsee', error);

    if (error) {
      
      if (error && (error.type === "card_error" || error.type === "validation_error")) {
        setMessage(error.message ?? "An unexpected error occurred.");
        triggerNotificationWithMessage(`Something went wrong`,'#D31148')
      } else {
        setMessage("An unexpected error occurred.");
        triggerNotificationWithMessage(`Something went wrong`,'#D31148')
      }

    } else {
    saveSalesData(orderPass2);
   
    setMessage("Payment successful! Manager will transfer the Talent Token to you soon. Payment will be captured upon transfer.");
    triggerNotificationWithMessage(`Talent Token successfully bought`, '#20AC61')
    }
 

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
   

    setIsLoading(false);
  };

  const paymentElementOptions = {};

  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit"  className="stripe-button">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Complete Payment"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
      {<div>
{notification && (
        <Notification
          message={notification.message}
          onClose={closeNotification}
          backgroundColor={notification.backgroundColor}
        />
      )}
</div>}
    </form>
    
  );
}